<template>
  <div>
    <div class="content" style="background:#6096fb" id='pdfDom'>
      <div class="title">高考志愿填报专家方案</div>
      <div class="form">
        <div class="info">
          <span> 学员姓名：{{ userInfo.user_name }}</span>
          <span> 成绩：{{ userInfo.score }}</span>
          <span> 位次：{{ userInfo.ranking }}</span>
          <span> 科类：{{ userInfo.is_wenli == 1 ? '文科' : '理科' }}</span>
        </div>

        <div class="table">
          <table border v-for="(item, i) in table" :key="i">
            <tbody>
              <tr rowspan="7" class="th">
                <td rowspan="7" style="width:130px; background:white">
                  <div class="px">
                    平行志愿{{ word[i] }}
                  </div>
                  <div v-if="item.name == '暂无'">
                    <div class="fc">
                      服从调剂
                    </div>
                  </div>
                  <div v-else>
                    <div class="fc" v-if="item.tiaoji">
                      服从调剂
                    </div>
                    <div class="fc" v-else style="color:red"> 拒绝调剂 </div>
                  </div>
                </td>
                <td colspan="5"> <span class="name">{{ item.name }}</span>
                  <span>所在地：{{ item.city_name }} </span>
                  <span>招生代号：{{ item.school_id }} </span>
                  <span>院校计划：{{ item.plan_num }} </span>
                  <span>录取原则：{{ item.lqgz }}</span>
                </td>
              </tr>
              <tr>
                <td class="th" style="width:100px">专业序号</td>
                <td class="th">专业代号/名称</td>
                <td class="th" style="width:200px">专业备注</td>
                <td class="th" style="width:85px">专业计划</td>
                <td class="th" style="width:85px">学费标准</td>
              </tr>
              <tr v-for="( val, key) in item.specialty" :key="key">
                <td>专业{{ word[key] }}</td>
                <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
                <td>{{ val.remarks }}</td>
                <td>{{ val.plan_num }}</td>
                <td>{{ val.tuition }}</td>
              </tr>
              <!-- 没有的填充一下 -->
              <tr v-for="test  in (5 - item.specialty.length)" :key="test + i + 'dfd'">
                <td style="height:35px"></td>
                <td style="height:35px"></td>
                <td style="height:35px"></td>
                <td style="height:35px"></td>
                <td style="height:35px"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="print">
      <el-button @click="doPrint">下载</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: '',
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      table: [],
    };
  },
  created() {
    this.table = this.$route.query.table
  },

  methods: {
    doPrint() {
      // window.print();
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getPdf("#pdfDom");
    },
  },
};
</script>

<style scoped lang='less'>
.content {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 40px 40px;

  .title {
    text-align: center;
    color: white;
    font-size: 28px;
    margin: 20px 0;
  }

  .form {
    background: white;
    padding: 20px 40px;
    border-radius: 7px;
    margin-top: 40px;

    .info {
      span {
        margin-right: 30px;
      }
    }

    table {
      border: 1px solid rgb(173, 169, 169);
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;

      td {
        border: 1px solid rgb(173, 169, 169);
        padding: 3px 10px;
      }
    }
  }
}

.print {
  position: fixed;
  bottom: 100px;
  right: 200px;
}
</style>
